<template>
  <ProductsComponent />
</template>

<script>
import ProductsComponent from '../../components/products/ProductsComponent.vue';

export default {
  name: 'Products',
  components: {
    ProductsComponent,
  },
};
</script>

<style scoped>

</style>
