<template>
  <VDialog
    v-model="visibility"
    max-width="600px"
  >
    <VCard class="pt-6 pb-4">
      <VCardText class="pb-0">
        <VContainer>
          <VRow class="align-center">
            <VCol cols="12" md="4">
              <img
                v-if="!item.item_details.path_img"
                :src="require('../../assets/images/product-desc.png')"
                alt=""
                style="width: 100%; height: 90px"
              >
              <img
                v-else
                :src="item.item_details.path_img"
                alt=""
                style="height: 90px"
                class="br-10 w-100"
              >
            </VCol>
            <VCol cols="12" md="8" class="px-0">
              <span class="font-16 text-black">
                {{ getItemDetailsProp('name') }}
              </span>
            </VCol>
            <VCol>
              <p class="text-black">{{ getItemDetailsProp('description') }}</p>
              <div class="d-flex align-center">
                <img :src="require('../../assets/images/korobka.png')" alt="">
                <span class="text-black d-flex ml-1">
                  {{ getItemDetailsProp('size_width') }}
                  x
                  {{ getItemDetailsProp('size_length') }}
                  x
                  {{ getItemDetailsProp('size_height') }}
                </span>
              </div>
              <div class="d-flex align-center mt-4">
                <img :src="require('../../assets/images/weight-icon.png')" alt="">
                <span class="text-black d-flex ml-1">
                  {{ getItemDetailsProp('weight_net') }} кг.
                </span>
              </div>
            </VCol>
          </VRow>
        </VContainer>
      </VCardText>
      <VCardActions>
        <VRow class="my-0 py-0 container justify-end">
          <VCol cols="12" md="3" sm="12" class="my-0 py-0">
            <VBtn
              dark
              block
              class="w-100 success-bg"
              @click="visibility = false"
            >
              OK
            </VBtn>
          </VCol>
        </VRow>
      </VCardActions>
    </VCard>
  </VDialog>
</template>

<script>
import _ from 'lodash';
import dialogMixin from '../../mixins/dialogMixin';

export default {
  name: 'ViewProductDialog',
  mixins: [dialogMixin],
  props: {
    item: {
      required: true,
    },
  },
  methods: {
    getItemDetailsProp(prop) {
      return _.get(this.item, `item_details.${prop}`);
    },
  },
};
</script>

<style scoped>

</style>
